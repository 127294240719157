.footer {
    background-color: #f8f9fa;
    padding: 2rem 0;
    margin-top: 3rem;
    border-top: 1px solid #dee2e6;
}

.support-text {
    text-align: center;
    color: #6c757d;
    margin: 0;
}

.support-text a {
    color: #007bff;
    text-decoration: none;
}

.support-text a:hover {
    text-decoration: underline;
}

.privacy-text {
    text-align: center;
    color: #6c757d;
    margin-top: 1rem;
    font-size: 0.9rem;
}

.privacy-link {
    text-align: center;
    margin-top: 1rem;
}

.privacy-link a {
    color: #6c757d;
    text-decoration: none;
}

.privacy-link a:hover {
    color: #007bff;
    text-decoration: underline;
} 