.news-section {
    width: 100%;
}

.news-item {
    /* Base styles */
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

/* Mobile styles */
@media (max-width: 767px) {
    .news-item {
        font-size: 14px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
    
    .news-item-title {
        font-size: 16px;
        font-weight: bold;
    }
} 